<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import CONSTANT from '@/constant/contant'
import axios from 'axios'
import { Device } from '@capacitor/device';

export default function () {

  const apiRequest = async function (type, uri, postParam) {

    var accessToken = localStorage.getItem('AUTHTOKEN');
    //var router = useRouter();
    var deviceInfo = await Device.getId();

    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {

        if (error.response.status == 401) {
          location.href = '/login';
        }
        return Promise.reject(error)
      }
    )

    if (accessToken != null) {
      return axios.request({
        method: type,
        url: CONSTANT.APIURL + uri,
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'X-Device-Id': deviceInfo.identifier
        },
        data: postParam
      });
    } else {
      return axios.request({
        method: type,
        url: CONSTANT.APIURL + uri,
        data: postParam,
        'X-Device-Id': deviceInfo.identifier
      });
    }

  };
  return { apiRequest }
}
</script>
