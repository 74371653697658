<template>
  <IonApp>
    <ion-page>
      <ion-router-outlet id="skeltonid"></ion-router-outlet>
    </ion-page>
  </IonApp>
</template>

<script>
import { IonPage, IonApp, IonRouterOutlet, alertController } from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, provide, inject, onMounted, ref, computed } from "vue";

import commonStore, { commonStoreKey } from "@/store/commonStore";
import useRestApi from "@/components/useRestApi";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonPage,
    IonRouterOutlet,
  },
  setup(context, props) {
    const route = useRoute();
    const router = useRouter();
    const { apiRequest } = useRestApi();

    // 共通ストアのProvide
    provide(commonStoreKey, commonStore);

    const flgUpdating = ref(false);
    /*
    const updateLoginInfo = async function() {
      flgUpdating.value = true;
      await commonStore.checkLoginInfo();
      flgUpdating.value = false;
    };*/

    /**
     * ページ遷移アクション
     */
    const doPageTransition = (url) => {
      router.push(url);
    };
    provide("doPageTransition", doPageTransition);

    var deferredPrompt;
    const isInstallable = ref(false);
    provide('isInstallable',computed(()=>isInstallable.value));

    onMounted(async () => {
      flgUpdating.value = true;
      await commonStore.checkLoginInfo();
      flgUpdating.value = false;

      if (commonStore.state.isLogin === true) {
        if (commonStore.state.studentCountAll > 0) {
          // 現在のルートが/の場合
          if (route.path === "/") {
            doPageTransition("/main/pages/top");
          }
          //doPageTransition("/main/pages/top");
        } else {
          doPageTransition("/studentregist");
        }
      }else{
        doPageTransition("/login");
      }
    });
    const registration = ref(null);
    const refreshing = ref(false);

    const updateApplication = (e) => {
      registration.value = e.detail;

     if (registration.value || registration.value.waiting) {
       registration.value.waiting.postMessage({type:'SKIP_WAITING'});
     }
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      isInstallable.value = true;
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener(
      'serviceWorkerUpdateEvent', updateApplication, { once: true }
    );

    if('serviceWorker' in navigator){
        navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (refreshing.value) return;
          refreshing.value = true;
          window.location.reload();
        }
    );
    }
    


    const onCLickPWAInstall = async () => {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
    }
    provide ('doPWAInstall',onCLickPWAInstall)

    return {
      route,
      router,
    };
  },
 
});
</script>


<style>



ion-content {
  
    --background: #3b99d9;
}

</style>