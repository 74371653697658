<template>
  <ion-page>

    <ion-content>
      <ion-header>
        <ion-toolbar class="ion-color">
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title size="medium">{{selectedPageTitle}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-router-outlet id="pagecontent" mode="md"></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,IonRouterOutlet } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';

export default {
  name: 'Pagetemplate',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRouterOutlet
  },
  setup() {
    const route = useRoute();
    const selectedPageTitle = computed(function(){
      return ('pageTitle' in route.meta) ? route.meta.pageTitle : '^';
    });

    return {  selectedPageTitle }
  }
}
</script>

<style>
  ion-content {
      --background: var(--ion-color-light);
  }
</style>
<style scoped>
ion-menu-button {
  color: var(--ion-color-white);
}

@media screen and (max-width: 990px) {
#pagecontent {

  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  top:50px;

}
}


@media screen and (min-width: 991px) {
#pagecontent {

  text-align: left;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  top:50px;

}
}

#pagecontent strong {
  font-size: 20px;
  line-height: 26px;
}

#pagecontent p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#pagecontent a {
  text-decoration: none;
}

ion-toolbar{
  background-color:dodgerblue;
  color:white;
}

</style>
