<template>
  <ion-page>
    <IonSplitPane content-id="main-content" id="split-panel">

      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list" class="pt-1">
            <img src="/assets/img/menuimg.png" class="ml-2" />
            <div class="row ml-3 mt-1 mb-3">
              <span class="badge badge-secondary col-5 col-sm-3">ログイン中</span>
              <span class="col-12 col-sm-10">{{ commonStore.state.userName }} さん</span>
              <br />
              <span class="badge badge-secondary col-5 col-sm-3">Eメール</span>
              <span class="col-12 col-sm-10">{{ commonStore.state.userEmail }}</span>
            </div>

            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <!--:class="{ selected: selectedIndex === i }" -->
              <ion-item @click="onClickMenu(i)" router-direction="root" :class="{ selected: selectIndexComputed === i }" :router-link="p.url" lines="none" detail="false" class="hydrated">
                <i :class="p.icon" class="ml-2 mr-4" style="width: 30px; text-align: center"></i>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-content id="main-content">
        <ion-tabs>
          <ion-router-outlet style="background-color" mode="md"></ion-router-outlet>

          <ion-tab-bar slot="bottom" class="ion-hide-lg-up">
            <ion-tab-button tab="top" href="/main/pages/top">
              <ion-icon class="fa-regular fa-square-list"></ion-icon>
              <ion-label>トップ</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="info" href="/main/pages/information">
              <ion-icon class="fa-solid fa-envelope"></ion-icon>
              <ion-label>お知らせ</ion-label>
            </ion-tab-button>


            <ion-tab-button tab="food" href="/main/pages/foodservices">
              <ion-icon class="fa-solid fa-utensils"></ion-icon>
              <ion-label>給食利用</ion-label>
            </ion-tab-button>

            <ion-tab-button tab="extend" href="/main/pages/extusages">
              <ion-icon class="fa-regular fa-timer"></ion-icon>
              <ion-label>預かり保育</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
      </ion-content>
    </IonSplitPane>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonTabs,
  IonIcon,
  IonTabButton,
  IonTabBar,
} from "@ionic/vue";
import {
  defineComponent,
  ref,
  provide,
  inject,
  onMounted,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { commonStoreKey } from "@/store/commonStore";

export default defineComponent({
  name: "Mainlayout",
  components: {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonPage,
    IonRouterOutlet,
    IonSplitPane,
    IonTabs,
    IonIcon,
    IonTabButton,
    IonTabBar,
  },
  setup() {
    const commonStore = inject(commonStoreKey);

    onMounted(() => {
      commonStore.checkLoginInfo();
    });

    const route = useRoute();
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "トップメニュー",
        url: "/main/pages/top",
        match: "/main/pages/top",
        icon: "fa-regular fa-square-list fa-xl",
      },

      {
        title: "園からお知らせ",
        url: "/main/pages/information",
        match: "/main/pages/information",
        icon: "fa-solid fa-envelope fa-xl",
      },

      {
        title: "給食利用状況",
        url: "/main/pages/foodservices",
        match: "/main/pages/foodservices",
        icon: "fa-solid fa-utensils fa-xl",
      },
      {
        title: "預かり利用状況",
        url: "/main/pages/extusages",
        match: "/main/pages/extusage",
        icon: "fa-regular fa-timer fa-xl",
      },
      {
        title: "一日預かり利用予約",
        url: "/main/pages/extreserve/list",
        match: "/main/pages/extreserve",
        icon: "fa-regular fa-calendar-clock fa-xl",
      },
      {
        title: "調査回答",
        url: "/main/pages/surveys",
        match: "/main/pages/survey",
        icon: "fa-regular fa-pen-to-square fa-xl",
      },
      {
        title: "入構証申請",
        url: "/main/pages/iccard",
        match: "/main/pages/iccard",
        icon: "fa-regular fa-address-card fa-xl",
      },
      {
        title: "体調管理",
        url: "/main/pages/healthcheck",
        match: "/main/pages/healthcheck",
        icon: "fa-regular fa-temperature-half fa-xl",
      },
      {
        title: "登録園児情報",
        url: "/main/pages/students",
        match: "/main/pages/student",
        icon: "fa-regular fa-screen-users fa-xl",
      },
      {
        title: "各種設定",
        url: "/main/pages/account",
        match: "/main/pages/account",
        icon: "fa-regular fa-cog fa-xl",
      },
    ];

    const onClickMenu = (i) => {
      selectedIndex.value = i;
    };

    const selectIndexComputed = computed(() => {
      // appPages.url と route.path が先頭一致するものを探す
      const index = appPages.findIndex((p) => {
        return route.path.startsWith(p.match);
      });
      
      if(index < 0) {
        return 0;
      }
      return index;
    });

    return {
      commonStore,
      selectedIndex,
      selectIndexComputed,
      onClickMenu,
      appPages,
      isSelected: (url) => (url === route.path ? "selected" : ""),
    };
  },
});
</script>

<style scoped>


ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-content {
  background-image: url("../../public/assets/img/menuback.png") !important;
}

@media(min-width: 992px) {
  ion-split-pane {
    margin:15px;
    border-radius: 15px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
  }
}
 


ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-content #main-content {
  --padding-start: 20px;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}
ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 1);
  --color: #ffffff;
}

ion-menu.md ion-item.selected ion-icon {
  /*color: var(--ion-color-primary);*/
  --color: #ffffff;
}

ion-menu.md ion-item ion-icon {
  color: #ffffff;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
