<script>
import { InjectionKey, reactive, readonly, inject } from 'vue'
import useRestApi from '@/components/useRestApi';

//const doPageTransition = inject('doPageTransition');
const { apiRequest } = useRestApi();

const state = reactive({
    isInitialized : false,
    isLogin : false,
    studentCount : 0,
    studentCountAll : 0,
    studentDatas: [],
    studentDatasAll:[],
    userName:'',
    userEmail:'',
    parentuser:{},
})

const setLoginInformation = function (param) {
    state.studentCount = param.studentCount;
    state.studentCountAll = param.studentCountAll;
    state.studentDatas = param.studentDatas;
    state.studentDatasAll = param.studentDatasAll;
    state.userName = param.userName;
    state.userEmail = param.userEmail;
    state.parentuser = param.parentuser;

    state.isLogin = true;
}

const getStudentByStnumber = function(stnumber) {
  return state.studentDatasAll.find(item => item.stnumber == stnumber);
}

/**
 * ログイン状態取得状態を確認し、ログイン情報の取得がされていることを保証する
 */
const checkLoginInfo = async function() {
  if(!state.isInitialized){
    await updateLoginInfo();
  }
}



/**
 * ログイン情報を更新する
 */
const updateLoginInfo = async function() {
  var accessToken = localStorage.getItem('AUTHTOKEN');

  if(accessToken === null){
    state.isLogin = false;
    //doPageTransition('/login');
    //location.href = '/login';
    return false;
  }

  var isLogin = await apiRequest('get','parentusers/isValidLogin',{});

  if(isLogin.data.result === false){
    localStorage.setItem('AUTHTOKEN','');
    state.isLogin = false;
    //doPageTransition('/login');
    //location.href = '/login';
    return false;
  }

  setLoginInformation(isLogin.data);
  
};

export default {
  state: readonly(state),
  checkLoginInfo,
  updateLoginInfo,
  setLoginInformation,
  getStudentByStnumber,

}

export const commonStoreKey = Symbol("commonStoreKey");

</script>
